<template>
    <el-scrollbar class="eCommerce-container">
        <div class="data-content-box shadow-box">
            <div class="top-bar">
                <span class="text">客户属性</span>
            </div>
            <div class="line-1">
                <div class="data-item" style="width: calc(50% - 15px)">
                    <div class="tab-title">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                您的成交客户主要集中在
                                <span style="color: #ff0000;" class="title-pause">
                                    <span v-for="(item, index) in infoDetail.provinces_distribution_data.focus_provinces" :key="`focus_provinces_${index}`">{{item}}</span>
                                </span>
                                等省份
                            </div>
                            <div slot="reference" class="popover-title">省份分布</div>
                        </el-popover>
                    </div>
                    <el-table :data="infoDetail.provinces_distribution_data.provinces_distribution_list" class="customTable" style="width: 100%; margin-top: 30px;"
                              :row-class-name="tableRowClassName" :header-cell-style="{height: '40px'}">
                        <el-table-column type="index" label="排名" width="100" align="center">
                            <template slot-scope="scope">
                                <div class="sort-img" v-if="scope.row.rank < 4">
                                    <img v-if="scope.row.rank === 1" :src="require('../../../../assets/images/student/num-01.png')" alt="">
                                    <img v-else-if="scope.row.rank === 2" :src="require('../../../../assets/images/student/num-02.png')" alt="">
                                    <img v-else :src="require('../../../../assets/images/student/num-03.png')" alt="">
                                </div>
                                <div class="sort-num" v-else>{{scope.row.rank | formatRank}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="province_name" label="省份" align="center"></el-table-column>
                        <el-table-column label="成交占比">
                            <template slot-scope="scope">
                                <div class="flex deal-box">
                                    <span>{{scope.row.trading_rate}}%</span>
                                    <el-progress :percentage="scope.row.percentage" color="#2DC079" :show-text="false"></el-progress>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="finish_price" label="成交客单价" align="center"></el-table-column>

                        <template slot="empty">
                            <div class="null-data" style="margin-top: 20px">
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </template>
                    </el-table>
                    <el-pagination class="pages-center" style="margin: 20px 0 0" v-if="infoDetail.provinces_distribution_data.provinces_distribution_list.length > 0"
                                   :current-page="listPages.currentPageNum"
                                   :page-size="listPages.eachPageNum"
                                   layout="prev, pager, next"
                                   :total="listPages.total"
                                   @current-change="pagesCurrentChange">
                    </el-pagination>
                </div>
                <div class="data-item" style="width: 1%; flex: 1; margin-left: 30px">
                    <div class="tab-title">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                <span style="color: #ff0000;">
                                    {{infoDetail.jobs_distribution_data.max | filterAttr}}
                                </span>
                                是购买的主力军
                            </div>
                            <div slot="reference" class="popover-title">职业分布</div>
                        </el-popover>
                    </div>
                    <div class="eChart-content">
                        <div class="data-content" id="occupationData"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-content-box shadow-box">
            <div class="top-bar">
                <span class="text">客户行为</span>
            </div>
            <div class="line-1">
                <div class="data-item" style="width: 100%;">
                    <div class="tab-title">
                        <div class="popover-title">下单及成交时段分布</div>
                    </div>
                    <div class="data-content" id="orderTransactionData" style="height: 280px"></div>
                </div>
            </div>
            <div class="line-1">
                <div class="data-item" style="width: calc(50% - 15px)">
                    <div class="tab-title">
                        <div class="popover-title">属性偏好</div>
                    </div>
                    <div class="attr-list">
                        <div class="attr-line" v-for="(attrItem, attrKey, attrIndex) in infoDetail.attr_preference_data" :key="`attr_preference_data_${attrIndex}`">
                            <div class="line-left">{{attrKey | filterAttr}}</div>
                            <div class="line-content">
                                <template v-for="(tagItem, tagIndex) in attrItem" >
                                    <div class="tag" v-if="tagItem" :key="`attrList_${tagIndex}`">{{tagItem}}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="data-item" style="width: 1%; flex: 1; margin-left: 30px">
                    <div class="tab-title">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                成交客户中
                                <span style="color: #ff0000;">
                                    {{infoDetail.buy_power_distribution_data.max | filterAttr}}
                                </span>
                                居多
                            </div>
                            <div slot="reference" class="popover-title">购买力分布</div>
                        </el-popover>
                    </div>
                    <div class="data-content" id="buyPowerData"></div>
                </div>
            </div>
            <div class="line-1">
                <div class="data-item" style="width: calc(50% - 15px)">
                    <div class="tab-title">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                成交客户对促销信息
                                <span style="color: #ff0000;">
                                    {{infoDetail.promotion_sensitivity_data.max | filterAttr}}
                                </span>
                            </div>
                            <div slot="reference" class="popover-title">促销敏感度</div>
                        </el-popover>
                    </div>
                    <div class="data-content" id="promotionalSensitivityData"></div>
                </div>
                <div class="data-item" style="width: 1%; flex: 1; margin-left: 30px">
                    <div class="tab-title">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                成交客户对评论
                                <span style="color: #ff0000;">
                                    {{infoDetail.evaluation_sensitivity_data.max | filterAttr}}
                                </span>
                            </div>
                            <div slot="reference" class="popover-title">评论敏感度</div>
                        </el-popover>
                    </div>
                    <div class="data-content" id="evaluationSensitivityData"></div>
                </div>
            </div>
        </div>
        <div class="data-content-box shadow-box">
            <div class="top-bar">
                <span class="text">消费能力</span>
            </div>
            <div class="line-1">
                <div class="data-item" style="width: calc(50% - 15px)">
                    <div class="tab-title">
                        <div class="popover-title">近90天购买频次分布</div>
                    </div>
                    <div class="data-content" id="buyFrequencyData"></div>
                </div>
                <div class="data-item" style="width: 1%; flex: 1; margin-left: 30px">
                    <div class="tab-title">
                        <div class="popover-title">近90天成交金额分布</div>
                    </div>
                    <div class="data-content" id="finishMoneyData"></div>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import { dataCentorTplGetCustomerAnalysisExam } from '@/utils/apis.js'
    export default {
        filters: {
            formatRank(val) {
                if (val < 10) {
                    return `0${val}`
                } else {
                    return val
                }
            },
            filterAttr(val) {
                let obj = {
                    individual: '个体或服务业从业者',
                    company: '公司职工',
                    student: '学生',
                    farmer: '农民',
                    city: '城市其他职业',
                    worker: '工人',
                    government: '公务员',
                    teacher: '教职工',
                    village: '农村其他职业',
                    doctor: '医务人员',
                    finance: '金融从业者',

                    market_time: '上市时间',
                    feature: '功能',
                    colour: '颜色',
                    hotspot: '选购热点',
                    scene: '适用场景',

                    loser: '收入很少',
                    blue: '蓝领',
                    low_white: '小白领',
                    advance_white: '高级白领',
                    luxury: '土豪',

                    evaluation_no_sensitivity: '不敏感',
                    evaluation_mild_sensitivity: '轻度敏感',
                    evaluation_medium_sensitivity: '中度敏感',
                    evaluation_high_sensitivity: '高度敏感',
                    evaluation_exceeding_sensitivity: '极度敏感',


                    promotion_no_sensitivity: '不敏感',
                    promotion_mild_sensitivity: '轻度敏感',
                    promotion_medium_sensitivity: '中度敏感',
                    promotion_high_sensitivity: '高度敏感',
                    promotion_exceeding_sensitivity: '极度敏感',
                }
                return obj[val]
            },
        },
        data() {
            return {
                denominator: null, // 第一个百分比的值 地区
                infoDetail: {
                    provinces_distribution_data: {
                        focus_provinces: [],
                        provinces_distribution_list: [],
                    },
                    jobs_distribution_data: {
                        list: [],
                        max: '',
                    },
                    attr_preference_data: {
                        market_time: [],
                        feature: [],
                        colour: [],
                        hotspot: [],
                        scene: [],
                    },
                    buy_power_distribution_data: {
                        xData: [],
                        yData: [],
                        max: ''
                    },
                    promotion_sensitivity_data: {
                        xData: [],
                        yData: [],
                        max: ''
                    },
                    evaluation_sensitivity_data: {
                        xData: [],
                        yData: [],
                        max: ''
                    },
                    buy_frequency_distribution_data: {
                        xData: [],
                        yData: [],
                    },
                    finish_money_distribution_data: {
                        xData: [],
                        yData: [],
                    },
                },
                occupationData: null, // 职业分布
                orderTransactionData: null, // 下单及成交时段分布
                buyPowerData: null, // 购买力分布
                promotionalSensitivityData: null, // 促销敏感度
                evaluationSensitivityData: null, // 评论敏感度
                buyFrequencyData: null, // 近90天购买频次分布
                finishMoneyData: null, // 近90天成交金额分布

                orderTransactionDataSeries: [], // 下单及成交时段 两条线

                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            // 表格颜色修改
            tableRowClassName({row, rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'statistics-warning-row';
                } else {
                    return '';
                }
            },
            // 详情
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id,
                    provinces_paging: 1,
                    provinces_page: this.listPages.currentPageNum,
                    provinces_pageSize: this.listPages.eachPageNum,
                }
                dataCentorTplGetCustomerAnalysisExam(params).then((res) => {
                    // 省份分布占比第一条数据的值
                    if (this.listPages.currentPageNum === 1) {
                        this.denominator = res.data.provinces_distribution_data.provinces_distribution_list[0].trading_rate;
                    }

                    // 省份分布
                    this.infoDetail.provinces_distribution_data.provinces_distribution_list = res.data.provinces_distribution_data.provinces_distribution_list.map(item => {
                        return {
                            id: item.id,
                            rank: item.rank,
                            finish_customer_number: item.finish_customer_number,
                            finish_price: item.finish_price,
                            province_id: item.province_id,
                            province_name: item.province_name,
                            trading_rate: item.trading_rate,
                            percentage: Number((item.trading_rate / this.denominator * 100 ).toFixed(2)),
                        }
                    })
                    this.listPages.total = res.data.provinces_distribution_data.provinces_distribution_total
                    this.infoDetail.provinces_distribution_data.focus_provinces = res.data.provinces_distribution_data.focus_provinces

                    // 职业分布
                    let jobsArr = []
                    for (const key in res.data.jobs_distribution_data.list) {
                        let item = res.data.jobs_distribution_data.list[key]
                        let nameTmp = {
                            individual: '个体或服务业从业者',
                            company: '公司职工',
                            student: '学生',
                            farmer: '农民',
                            city: '城市其他职业',
                            worker: '工人',
                            government: '公务员',
                            teacher: '教职工',
                            village: '农村其他职业',
                            doctor: '医务人员',
                            finance: '金融从业者',
                        }
                        let obj = {
                            name: nameTmp[key],
                            value: item
                        }
                        jobsArr.push(obj)
                        this.infoDetail.jobs_distribution_data.max = nameTmp[key]
                    }
                    this.infoDetail.jobs_distribution_data.list = jobsArr
                    this.infoDetail.jobs_distribution_data.max = res.data.jobs_distribution_data.max

                    // 下单及成交时段分布
                    let orderArr = res.data.times_distribution_data.map(item => {
                        return Number(item.ordering_rate)
                    })
                    let transitionArr = res.data.times_distribution_data.map(item => {
                        return Number(item.ordered_rate)
                    })
                    this.orderTransactionDataSeries = [
                        {
                            name: '下单客户占比',
                            type: 'line',
                            data: orderArr,
                            // data: this.changeData(orderArr),
                            smooth: true,
                            symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: "#0083FF",
                                }
                            },
                        },
                        {
                            name: '成交客户占比',
                            type: 'line',
                            data: transitionArr,
                            // data: this.changeData(transitionArr),
                            smooth: true,
                            symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: "#E43B3E",
                                }
                            },
                        },
                    ];

                    // 属性偏好
                    this.infoDetail.attr_preference_data = res.data.attr_preference_data

                    // 购买力分布
                    this.infoDetail.buy_power_distribution_data = this.getXYData(res.data.buy_power_distribution_data.list)
                    this.infoDetail.buy_power_distribution_data.max = res.data.buy_power_distribution_data.max

                    // 促销敏感度
                    this.infoDetail.promotion_sensitivity_data = this.getXYData(res.data.promotion_sensitivity_data.list)
                    this.infoDetail.promotion_sensitivity_data.max = res.data.promotion_sensitivity_data.max

                    // 评论敏感度
                    this.infoDetail.evaluation_sensitivity_data = this.getXYData(res.data.evaluation_sensitivity_data.list)
                    this.infoDetail.evaluation_sensitivity_data.max = res.data.evaluation_sensitivity_data.max

                    // 近90天购买频次分布
                    this.infoDetail.buy_frequency_distribution_data = this.getXYData(res.data.buy_frequency_distribution_data)

                    // 近90天成交金额分布
                    this.infoDetail.finish_money_distribution_data = this.getXYData(res.data.finish_money_distribution_data)

                    this.occupationData = this.$echarts.init(document.getElementById('occupationData'));
                    this.orderTransactionData = this.$echarts.init(document.getElementById('orderTransactionData'));
                    this.buyPowerData = this.$echarts.init(document.getElementById('buyPowerData'));
                    this.promotionalSensitivityData = this.$echarts.init(document.getElementById('promotionalSensitivityData'));
                    this.evaluationSensitivityData = this.$echarts.init(document.getElementById('evaluationSensitivityData'));
                    this.buyFrequencyData = this.$echarts.init(document.getElementById('buyFrequencyData'));
                    this.finishMoneyData = this.$echarts.init(document.getElementById('finishMoneyData'));

                    let resizeTimer = null;
                    window.onresize = () => {
                        clearTimeout(resizeTimer);
                        resizeTimer = setTimeout(() => {
                            this.occupationData.resize()
                            this.orderTransactionData.resize()
                            this.buyPowerData.resize()
                            this.promotionalSensitivityData.resize()
                            this.evaluationSensitivityData.resize()
                            this.buyFrequencyData.resize()
                            this.finishMoneyData.resize()
                        }, 100)
                    }
                    this.setOccupationOption()
                    this.setOrderTransactionData()
                    this.getBuyPowerData()
                    this.getPromotionalSensitivityData()
                    this.getEvaluationSensitivityData()
                    this.getBuyFrequencyData()
                    this.getFinishMoneyData()
                }).catch((err) => {})
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getInfo()
            },

            // 获取x y 的值
            getXYData(data) {
                let obj = {
                    xData: [],
                    yData: []
                }
                for (const key in data) {
                    let item = data[key]
                    let nameTmp = {
                        loser: '收入很少',
                        blue: '蓝领',
                        low_white: '小白领',
                        advance_white: '高级白领',
                        luxury: '土豪',

                        promotion_no_sensitivity: '不敏感',
                        promotion_mild_sensitivity: '轻度敏感',
                        promotion_medium_sensitivity: '中度敏感',
                        promotion_high_sensitivity: '高度敏感',
                        promotion_exceeding_sensitivity: '极度敏感',

                        evaluation_no_sensitivity: '不敏感',
                        evaluation_mild_sensitivity: '轻度敏感',
                        evaluation_medium_sensitivity: '中度敏感',
                        evaluation_high_sensitivity: '高度敏感',
                        evaluation_exceeding_sensitivity: '极度敏感',

                        one: '一次',
                        two: '二次',
                        three: '三次',
                        four: '四次',
                        five: '五次',
                        other: '五次以上',

                        first: '0~159',
                        second: '159~199',
                        third: '199~369',
                        fourth: '369~699',
                        fifth: '699~740',
                        sixth: '740以上',
                    }
                    obj.xData.push(nameTmp[key])
                    obj.yData.push(item)
                }
                return obj
            },

            // 将等于0的数值改为0.1，不然会出现数据无法显示 原因是：yAxis这个的type: 'log'
            changeData(data) {
                return data.map(item => {
                    return item === 0 ? 0.1 : item
                })
            },
            // 职业分布环形 eCharts
            setOccupationOption() {
                this.occupationData.setOption({
                    legend: {
                        orient: 'vertical', // 设置图例的方向
                        right: 0,
                        top: 'center',
                    },
                    grid: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    },
                    series: [{
                        name: '职业分布',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        center: ['40%', '50%'], //图的位置，距离左跟上的位置
                        data: this.infoDetail.jobs_distribution_data.list,
                        barWidth: 18,
                        color: ['#2dc079', '#4FC5EA', '#6C6FBF', '#F86846', '#78C446', '#FFB11A', '#5ED8A9', '#8F55E7', '#605AD8', '#F9CD33', '#ff0000'], //设置对应块的数据
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold',
                                formatter: '{b}:{c}%'
                            }
                        },
                    }]
                });
            },
            // 下单及成交时段分布 折线图 eCharts
            setOrderTransactionData() {
                this.orderTransactionData.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
                            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'log',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '下单客户占比' || params[i].seriesName === '成交客户占比') {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value}%`;
                                } else {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value}`;
                                }
                            }
                            return res
                        },
                    },
                    legend: {
                        icon: 'circle',
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 20,
                        left: 80,
                        right: 40,
                        bottom: 80,
                    },
                    series: this.orderTransactionDataSeries
                }, true);
            },

            // 柱状图 eCharts
            setEChartsOption(item, xData, yData, title, colorBottom, colorTop) {
                item.setOption({
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: '#9A9A9A'
                            },
                            formatter: '{value} %'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            }
                        },
                        formatter: '{a}<br />{b} : {c}%',
                    },
                    grid: {
                        top: 20,
                        left: 50,
                        right: 0,
                        bottom: 25,
                    },
                    series: [{
                        name: title,
                        type: 'bar',
                        data: yData,
                        barWidth: 18,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#666',
                            formatter: '{c}%'
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: colorBottom, // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: colorTop, // 100% 处的颜色
                                    }
                                ], false)
                            },
                        },
                    }]
                });
            },
            // 柱状图——购买力分布
            getBuyPowerData() {
                let xData = this.infoDetail.buy_power_distribution_data.xData
                let yData = this.infoDetail.buy_power_distribution_data.yData
                this.setEChartsOption(this.buyPowerData, xData, yData, '购买力分布', '#3BC37F', '#ADE2C3')
            },
            // 柱状图——促销敏感度
            getPromotionalSensitivityData() {
                let xData = this.infoDetail.promotion_sensitivity_data.xData
                let yData = this.infoDetail.promotion_sensitivity_data.yData
                this.setEChartsOption(this.promotionalSensitivityData, xData, yData, '促销敏感度', '#F9757F', '#FDA198')
            },
            // 柱状图——评论敏感度
            getEvaluationSensitivityData() {
                let xData = this.infoDetail.evaluation_sensitivity_data.xData
                let yData = this.infoDetail.evaluation_sensitivity_data.yData
                this.setEChartsOption(this.evaluationSensitivityData, xData, yData, '评论敏感度', '#428AF5', '#60C5FF')
            },
            // 柱状图——近90天购买频次分布
            getBuyFrequencyData() {
                let xData = this.infoDetail.buy_frequency_distribution_data.xData
                let yData = this.infoDetail.buy_frequency_distribution_data.yData
                this.setEChartsOption(this.buyFrequencyData, xData, yData, '近90天购买频次分布', '#FE9B1C', '#FFD83B')
            },
            // 柱状图——近90天成交金额分布
            getFinishMoneyData() {
                let xData = this.infoDetail.finish_money_distribution_data.xData
                let yData = this.infoDetail.finish_money_distribution_data.yData
                this.setEChartsOption(this.finishMoneyData, xData, yData, '近90天购买频次分布', '#9A58FF', '#D2B5FF')
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .shadow-box {
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
        border-radius: 10px;
        background: #fff;
    }
    .data-content-box {
        margin: 20px;
        padding: 20px;
        .top-bar {
            width: 218px;
            height: 50px;
            background: url("../../../../assets/images/student/customer-info.png") no-repeat;
            .text {
                padding-left: 70px;
                line-height: 50px;
                color: #fff;
                font-size: 20px;
            }
        }
    }
    .line-1 {
        display: flex;
        margin: 30px 0 0;
    }
    .data-item {
        display: flex;
        flex-direction: column;
        .eChart-content {
            height: 1%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .tab-title {
        padding-left: 15px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
        }
        .popover-title {
            font-size: 18px;
            line-height: 1;
            display: inline-block;
        }
    }
    .data-content {
        height: 350px;
        width: 100%;
        margin-top: 30px;
    }

    .sort-num {
        display: inline-block;
        background: #E7F6EF;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
    }
    .sort-img {
        display: inline-block;
        img {
            display: block;
        }
    }
    .deal-box {
        align-items: center;
        .el-progress {
            width: 1%;
            flex: 1;
            margin-left: 15px;
            margin-right: 20px;
        }
    }
    .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        .no-img {
            max-width: 200px;
        }
        .text {
            color: #999;
            margin-top: 12px;
            padding: 0 20px;
        }
    }
    .el-table {
        ::v-deep .el-table__row.statistics-warning-row {
            background: #F9FFFC;
        }
    }
    .title-pause {
        span {
            &:after {
                content: '、';
            }
            &:last-child:after {
                content: none;
            }
        }
    }
    .pages-center {
        text-align: right;
    }

    .attr-list {
        margin-top: 30px;
        border: 1px solid #E5E5E5;
        .attr-line {
            display: flex;
            .line-left {
                width: 100px;
                /*min-height: 100px;*/
                text-align: center;
                background: #E7F6EF;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #E5E5E5;
            }
            .line-content {
                width: 1%;
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                padding: 10px 20px 20px 10px;
                border-bottom: 1px solid #E5E5E5;
                .tag {
                    color: #2DC079;
                    line-height: 1;
                    margin-left: 10px;
                    margin-top: 10px;
                    background: #E7F6EF;
                    padding: 9px 8px;
                    border: 1px solid #2DC079;
                }
            }
            &:last-child {
                .line-left, .line-content {
                    border-bottom: none;
                }
            }
        }
    }
</style>